import React, { useEffect } from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from 'mdbreact';
import { reactAppVersion } from '../../util/util';
import  useCXOneChat from '../../helpers/useCXOneChat';
import store from '../../store';
export const OldFooter = () => {
  return (
    <MDBFooter>
      <MDBContainer fluid className="text-center text-md-left footerNew">
        <div className="footerBg" />
        <MDBRow className="rowInner">
          {/* <MDBCol className="posTop mob-show" md='12' sm='12' lg='12'>
            <ul className="mob-footer-links">
              <li><a href="https://support.earthlink.net" target="_blank" rel="noopener noreferrer">Support</a></li>
              <li><a href="/">Live Chat</a></li>
              <li><a href="https://www.earthlink.net/security-and-tools/" target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
            </ul>
          </MDBCol> */}
          <MDBCol className="posTop" md="12" sm="12" lg="3">
            <div className="text-left">
              <h5 className="footerTitle">
                <span>Residential</span>
              </h5>
            </div>
            <div className="footerLinks">
              <ul>
                <li><a href="https://www.earthlink.net/internet/" target="_blank" rel="noopener noreferrer">Hyperlink<sup>TM</sup> Internet</a></li>
                <li><a href="https://www.earthlink.net/security-and-tools/" target="_blank" rel="noopener noreferrer">Security &amp; tools</a></li>
              </ul>
            </div>
          </MDBCol>
          <MDBCol className="posTop" md="12" lg="3" sm="12">
            <div className="text-left">
              <h5 className="footerTitle">Business</h5>
            </div>
            <div className="footerLinks">
              <ul>
                <li><a href="https://www.earthlink.net/internet/business-internet/" target="_blank" rel="noopener noreferrer">Hyperlink<sup>TM</sup> Internet</a></li>
                <li><a href="https://www.earthlink.net/digital-marketing/" target="_blank" rel="noopener noreferrer">Digital Marketing</a></li>
                <li><a href="https://www.earthlink.net/security-and-tools/" target="_blank" rel="noopener noreferrer">Security &amp; tools</a></li>
              </ul>
            </div>
          </MDBCol>

          <MDBCol className="posTop" md="12" sm="12" lg="3">
            <div className="text-left">
              <h5 className="footerTitle">My EarthLink</h5>
            </div>
            <div className="footerLinks">
              <ul>
                <li><a href="https://webmail.earthlink.net" target="_blank" rel="noopener noreferrer">WebMail</a></li>
                <li><a href="https://myaccount.earthlink.net" target="_blank" rel="noopener noreferrer">My Account</a></li>
                <li><a href="https://support.earthlink.net" target="_blank" rel="noopener noreferrer">Support</a></li>
                <li><a href="https://www.earthlink.net/blog/" target="_blank" rel="noopener noreferrer">Blog</a></li>
              </ul>
            </div>
          </MDBCol>

          <MDBCol className="posTop" md="12" sm="12" lg="3">
            <div className="text-left">
              <h5 className="footerTitle">EarthLink</h5>
            </div>
            <div className="footerLinks">
              <ul>
                <li><a href="https://www.earthlink.net/about-us/contact/" target="_blank" rel="noopener noreferrer">Contact</a></li>
                <li><a href="https://www.earthlink.net/career" target="_blank" rel="noopener noreferrer">Careers</a></li>
                <li><a href="https://www.earthlink.net/partners/" target="_blank" rel="noopener noreferrer">Affiliate Partners</a></li>
                <li><a href="https://www.earthlink.net/tcs/" target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
              </ul>
            </div>
          </MDBCol>

          <MDBCol className="posTop text-center footerLogos middle" lg="8" md="12" sm="12">
            <div className="logoFlex">
              <img className="footerLogo img-fluid" src="/images/footer-logo.png" alt="25-years-home-to-atlanta" />
              <img className="footerLogo img-fluid"  src="/images/hsi-best-isp-2020-white.png" alt="#1 Best Internet Providers - Customer Satisfaction badge" />
              <img className="footerLogo img-fluid" src="/images/affordable-award-gold.png" alt="affordable award gold" />
              <img className="footerLogo img-fluid" src="/images/fast-uai.png" alt="fastest internet speeds" />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid className="text-center footerNew footerLast">
        <p className="copyrightTxt">
          &copy; Copyright {new Date().getFullYear()} EarthLink LLC · All rights reserved ·
              <a href="https://www.earthlink.net/privacy-policy/" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>{' '}
          <span style={{ color: '#000000' }}>
            Version:{reactAppVersion}
          </span>
        </p>
      </MDBContainer>
    </MDBFooter>
  );
};

export const NewFooter = () => {
  const { insertChatScript, isCXOneChatReady } = useCXOneChat();
  const isAuth = store.getState().LoginState.auth;

  useEffect(() => {
    if (!isCXOneChatReady() && isAuth) {
      insertChatScript(isAuth);
    }
  }, [insertChatScript, isCXOneChatReady, isAuth]);
  return (
    <MDBFooter>
      <MDBContainer fluid className="text-center footer-top">
        <MDBRow className="text-dark justify-content-center py-3 px-4">
          <div className="mr-1"><a href="https://www.earthlink.net/internet/" target="_blank" rel="noopener noreferrer">Residential Internet</a>{' '}·</div>
          <div className="mr-1"><a href="https://www.earthlink.net/internet/business-internet/" target="_blank" rel="noopener noreferrer">Business Internet</a>{' '}·</div>
          <div className="mr-1"><a href="https://www.earthlink.net/partners/" target="_blank" rel="noopener noreferrer">Affiliate Partners</a>{' '}·</div>
          <div className="mr-1"><a href="https://www.earthlink.net/about-us/contact/" target="_blank" rel="noopener noreferrer">Contact</a>{' '}·</div>
          <div className="mr-1"><a href="https://help.earthlink.net/portal/en/home" target="_blank" rel="noopener noreferrer">Support</a></div>
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid className="text-center footer-bottom">
        <MDBRow className="flex-column flex-lg-row align-items-center">
          <MDBCol className="order-lg-0 pt-3 pb-lg-3">&copy; Copyright {new Date().getFullYear()} EarthLink LLC · All rights reserved</MDBCol>
          <MDBCol className="order-lg-2 pb-3 pt-lg-3">
            <a href="https://www.earthlink.net/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>{' '}·{' '}
            <a href="https://www.earthlink.net/tcs/interest-based-ads/" target="_blank" rel="noopener noreferrer">Interest-Based Ads</a>
          </MDBCol>
          <div className="d-flex align-items-end order-lg-1">
            <img
              src="/images/footer-50.png"
              width={80}
              alt="EarthLink Logo"
            />
          </div>
        </MDBRow>
      </MDBContainer>
    </MDBFooter>
  );
};
