import * as types from '../actions/Types';
import { isUndefinedNullOrEmpty, setCallLogId, getStoreAddr, setStoreAddr } from '../../common/common';
import { filterAvailableBundles } from '../../helpers/OrderHelperFunctions';

export const initialState = {
  accountCheck: {},
  addressIsSame: true,
  businessInfo: {},
  callLogId: '',
  checkingAccount: false,
  chgQuestionInfo: {},
  cardErrorModalMsg: 'Sorry, we were unable to authorize your Credit Card. Would you like to try a different Credit Card?',
  errorMessage: '',
  hasError: false,
  isBusinessAddress: false,
  isReachOrder: false,
  reachOrderCompleted: false,
  isReprovisioning: false,
  loading: false,
  orderSubLoading: false,
  addToCartLoading: false,
  storesLoading: false,
  normalizedAddress: {},
  orderInfo: {},
  orderSubmitResponse: {},
  paymentInfo: {},
  planInfo: {},
  serviceabilityCheck: {},
  vasPlanInfo: [],
  addressHistory: [],
  productHistory: [],
  sdpResponse: {},
  languagePref: '',
  ebbConfirmationNo: '',
  wirelessCartInfo: {
    wirelessPlanInfo: [],
    wirelesServeRef: '',
  },
  mobileOnly: false,
  deviceCheckLoader: false,
  retailStores: [],
  isElnkMailboxReq: false,
  retailStoresAddr: getStoreAddr(),
  storesLoaded: false,
  scheduleData: {},
  scheduleDataLoading: false,
  reqInstallDateTime: '',
  promoDetails: {},
  promoDes: '',
  applyPromoErr: '',
  promoErr: false,
  manualPromoCode: '',
  discountedPrice: '',
  cartPromoCode: '',
  iccids: [],
  promoTrackerDetails: {},
  promoTrackerErrData: {},
  promoTrackerStatus: '',
  oneTimeCardData: {},
  isApplyPromoLoading: false,
  serviceTerms: [],
  isSkipCreateAccount: false,
  isVasSkipCreateAccount: false,
  isPreferredLangSpanish: false,
  currentStep: null,
  bundles: [],
  bundlesLoading: false,
  bundlesCart: {},
  isSimCardFlow: false,
  isSuccessMessageTerm:false,
  isTermInfoConfirm: false,
  orderAccessToken: '',
};

export default function OrderReducer(state = initialState, action) {
  let newState = { ...state };
  let item = null;
  let data = null;

  switch (action.type) {

    case types.RESET_STATE:
      newState.accountCheck = {};
      newState.addressIsSame = true;
      newState.businessInfo = {};
      newState.callLogId = '';
      newState.checkingAccount = false;
      newState.chgQuestionInfo = {};
      newState.cardErrorModalMsg = 'Sorry, we were unable to authorize your Credit Card. Would you like to try a different Credit Card?';
      newState.errorMessage = '';
      newState.hasError = false;
      newState.isPreferredLangSpanish = false;
      newState.stepPage = null;
      newState.isBusinessAddress = false;
      newState.isReachOrder = false;
      newState.reachOrderCompleted = false;
      newState.loading = false;
      newState.orderSubLoading = false;
      newState.addToCartLoading = false;
      newState.storesLoading = false;
      newState.normalizedAddress = {};
      newState.orderInfo = {};
      newState.orderSubmitResponse = {};
      newState.paymentInfo = {};
      newState.planInfo = {};
      newState.discountedPrice = '';
      newState.serviceabilityCheck = {};
      newState.vasPlanInfo = [];
      newState.languagePref = '';
      newState.ebbConfirmationNo = '';
      newState.isElnkMailboxReq = false;
      newState.wirelessCartInfo = {
        wirelessPlanInfo: [],
        wirelesServeRef: '',
      };
      newState.mobileOnly = false;
      newState.scheduleData = {};
      newState.scheduleDataLoading = false;
      newState.iccids = [];
      newState.promoDes = '';
      newState.applyPromoErr = '';
      newState.promoDetails = {};
      newState.promoErr = false;
      newState.promoTrackerStatus = '';
      newState.promoTrackerErrData = {};
      newState.promoTrackerDetails = {};
      newState.oneTimeCardData = {};
      newState.isApplyPromoLoading = false;
      newState.manualPromoCode = '';
      newState.serviceTerms = [];
      newState.bundles = [];
      newState.bundlesCart = {};
      newState.orderAccessToken = '';
      return newState;

    case types.RESET_HISTORY:
      newState.addressHistory = [];
      newState.productHistory = [];
      return newState;

    case types.CLEAR_APPLY_PROMO:
      newState.promoDes = '';
      newState.applyPromoErr = '';
      newState.promoDetails = {};
      newState.promoErr = false;
      newState.promoTrackerStatus = '';
      newState.promoTrackerErrData = {};
      newState.promoTrackerDetails = {};
      newState.serviceabilityCheck.serviceTerm = state.serviceTerms;
      return newState;

    //AXIOS FULFILLED CALL HANDLERS

    case types.GET_HISTORY_FULFILLED:
      data = isUndefinedNullOrEmpty(action.payload.data) ? '' : action.payload.data;
      if (action.payload.status === 200 && data) {
        newState.addressHistory = data.addressHistory || [];
        newState.productHistory = data.productHistory || [];
      } else {
        newState.hasError = true;
      }
      return newState;

    case types.INITIAL_ORDER_CREATE_FULFILLED:
      data = isUndefinedNullOrEmpty(action.payload.data) ? '' : action.payload.data;
      if (action.payload.status === 201 && data) {
        newState.orderInfo = data;
      } else {
        newState.hasError = true;
      }
      newState.loading = false;
      return newState;

    case types.SET_REQ_INSTALL_DATE_TIME:
      newState.reqInstallDateTime = action.payload;
      return newState;

    case types.SUBMIT_ORDER_FULFILLED:
      data = isUndefinedNullOrEmpty(action.payload.data) ? '' : action.payload.data;
      if (action.payload.status === 200 && data) {
        if (Object.prototype.hasOwnProperty.call(data, 'confirmationNo'))
          newState.orderSubmitResponse.confirmationNo = data.confirmationNo;
        if (Object.prototype.hasOwnProperty.call(data, 'customerNumber'))
          newState.orderSubmitResponse.customerNumber = data.customerNumber;
        if (Object.prototype.hasOwnProperty.call(data, 'reqInstallDate'))
          newState.orderSubmitResponse.reqInstallDate = data.reqInstallDate;
      }
      else
        newState.hasError = true;
      newState.loading = false;
      newState.orderSubLoading = false;
      return newState;

    case types.UPDATE_ORDER_FULFILLED:
      newState.hasError = false;
      newState.loading = false;
      newState.orderSubLoading = false;
      newState.addToCartLoading = false;
      item = isUndefinedNullOrEmpty(action.payload.data) ? '' : action.payload.data;
      if (action.payload.status === 200 && item) {
        newState.orderInfo = item;
        newState.addressHistory = item.addressHistory;
        newState.productHistory = item.productHistory;
      }
      else
        newState.hasError = true;
      newState.loading = false;
      newState.addToCartLoading = false;
      return newState;

    case types.CHECK_SERVICEABILITY_FULFILLED:
      data = isUndefinedNullOrEmpty(action.payload.data) ? '' : action.payload.data;
      if (action.payload.status === 200 && !isUndefinedNullOrEmpty(data)) {
        let servicePlans = [];
        let vasArray = [];
        let fixedWirelessArray = [];

        if (Object.prototype.hasOwnProperty.call(data, 'products')) {
          const plans = data.products;
          plans.forEach((plan, index) => {
            if (Object.prototype.hasOwnProperty.call(plan, 'downstreamSpd')) {
              servicePlans[index] = plan;
            }
          });
          newState.serviceabilityCheck.plans = servicePlans;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'vasProducts')) {
          const vasProducts = data.vasProducts;
          vasProducts.forEach((vasPlan) => {
            vasArray.push(vasPlan);
          });
          newState.serviceabilityCheck.vasProducts = vasArray;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'fixedWirelessProducts')) {
          const fixedWirelessProducts = data.fixedWirelessProducts;
          fixedWirelessProducts.forEach((fwPlan) => {
            fixedWirelessArray.push(fwPlan);
          });
          newState.serviceabilityCheck.fixedWirelessProducts = fixedWirelessArray;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'generalTerms')) {
          newState.serviceabilityCheck.generalTerms = data.generalTerms;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'normalizedAddress')) {
          newState.normalizedAddress = data.normalizedAddress;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'nbcScore')) {
          newState.serviceabilityCheck.nbcScore = data.nbcScore;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'suggestedAddresses')) {
          let suggestedAddresses = data.suggestedAddresses;
          newState.serviceabilityCheck.suggestedAddresses = suggestedAddresses;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'callLogId')) {
          const callLogId = data.callLogId;
          setCallLogId(callLogId);
          newState.callLogId = callLogId;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'serviceTerm')) {
          newState.serviceabilityCheck.serviceTerm = data.serviceTerm;
          newState.serviceTerms = data.serviceTerm;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'canScheduleAppt')) {
          newState.serviceabilityCheck.canScheduleAppt = data.canScheduleAppt;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'adslShippingCost')) {
          newState.serviceabilityCheck.adslShippingCost = data.adslShippingCost;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'mobile')) {
          newState.serviceabilityCheck.mobileCoverage = data.mobile;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'mobileProducts')) {
          newState.serviceabilityCheck.mobileProducts = data.mobileProducts;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'extendedInfo')) {
          newState.serviceabilityCheck.extendedInfo = data.extendedInfo;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'bundles')) {
          newState.serviceabilityCheck.bundles = data.bundles;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'existingCustomerDetails')) {
          newState.serviceabilityCheck.existingCustomerDetails = data.existingCustomerDetails;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'mrcPosProducts')) {
          newState.serviceabilityCheck.mrcPosProducts = data.mrcPosProducts;
        }

        if (Object.prototype.hasOwnProperty.call(data, 'validateMetadata')) {
          newState.serviceabilityCheck.validateMetadata = data.validateMetadata;
        }
      }
      else
        newState.hasError = true;
      newState.loading = false;
      return newState;

    case types.GET_SCHEDULE_APT_FULFILLED:
    case types.GET_SCHEDULE_APT_REJECTED:
      newState.scheduleDataLoading = false;
      newState.scheduleData = action.payload.data;
      return newState;

    case types.ACCOUNT_CHECK_FULFILLED:
      data = isUndefinedNullOrEmpty(action.payload.data) ? '' : action.payload.data
      if (action.payload.status === 200 && data) {
        if (Object.prototype.hasOwnProperty.call(data, 'suggestedIsValid')) {
          newState.accountCheck.emailIsAvailable = true
          newState.checkingAccount = false
        }
        else {
          if (Object.prototype.hasOwnProperty.call(data, 'suggestedId')) {
            const { suggestedId } = data;
            const suggestions = []
            suggestedId.map(suggestion => (
              suggestions.push(suggestion.userId)
            ))
            newState.accountCheck.emailIsAvailable = false;
            newState.checkingAccount = false
            newState.accountCheck.suggestedIds = suggestions;
          } else {
            newState.hasError = true;
            newState.checkingAccount = false
            newState.accountCheck.emailIsAvailable = false;
          }
        }
      }
      else {
        newState.hasError = true;
        newState.checkingAccount = false
        newState.accountCheck.emailIsAvailable = false;
      }
      return newState;

    case types.SERVICE_CHECK_FULFILLED:
      newState.serviceabilityCheck.serviceCheckId = action.payload
      newState.loading = false;
      if (action.payload && (action.payload.status !== 201))
        newState.hasError = true;
      return newState;

    case types.GET_VAS_PRODUCTS_FULFILLED:
      if (action.payload && (action.payload.status === 200) && !isUndefinedNullOrEmpty(action.payload.data))
        newState.serviceabilityCheck.vasProducts = action.payload.data;
      return newState;

    case types.CREATE_SDP_TICKET_FULFILLED:
      if (action.payload && action.payload.status === 200) {
        newState.sdpResponse = action.payload;
      }
      return newState;

    case types.VALIDATE_CARD_FULFILLED:
      newState.loading = false;
      return newState;

    case types.CHECK_DEVICE_COMPATIBILITY_FULFILLED:
    case types.CHECK_DEVICE_COMPATIBILITY_REJECTED:
      newState.deviceCheckLoader = false;
      return newState;

    //UPDATE STORE CALLS

    case types.SAVE_IS_REPROVISIONING_INFO:
      newState.isReprovisioning = action.payload;
      return newState;

    case types.SAVE_CHALLENGE_QUESTION_INFO:
      newState.chgQuestionInfo = action.payload;
      return newState;

    case types.UPDATE_IS_BUSINESS_ADDRESS:
      newState.isBusinessAddress = action.payload;
      return newState;

    case types.UPDATE_IS_REACH_ORDER:
      newState.isReachOrder = action.payload;
      return newState;

    case types.REACH_ORDER_COMPLETED:
      newState.reachOrderCompleted = action.payload;
      return newState;

    case types.SAVE_BUSINESS_INFO:
      newState.businessInfo = action.payload;
      return newState;

    case types.SAVE_PAYMENT_INFO:
      newState.paymentInfo = action.payload
      return newState;

    case types.SET_ONE_TIME_PAYMENT_INFO:
      newState.oneTimeCardData = action.payload;
      return newState;

    case types.UPDATE_CUST_TC:
      newState.paymentInfo.customerAcceptedTC = action.payload;
      return newState;

    case types.UPDATE_ERROR_MSG:
      newState.errorMessage = action.payload;
      return newState;

    case types.UPDATE_CARD_ERROR:
      newState.cardErrorModalMsg = action.payload;
      return newState;

    case types.SAVE_PLAN_INFO:
      newState.planInfo = action.payload;
      return newState;

    case types.SAVE_DISCOUNTED_PRICE:
      newState.discountedPrice = action.payload;
      return newState;

    case types.SAVE_VAS_PLAN_INFO:
      newState.vasPlanInfo = action.payload;
      return newState;

    case types.SAVE_PASSWORD:
      newState.accountCheck.password = action.payload;
      return newState;

    case types.SAVE_ADDRESS_IS_SAME:
      newState.addressIsSame = action.payload;
      return newState;

    case types.SET_EBB_CONFIRMATION_NUM:
      newState.ebbConfirmationNo = action.payload;
      return newState;

    case types.SET_OPTIONAL_MAILBOX:
      newState.isElnkMailboxReq = action.payload;
      return newState;

    case types.SAVE_WIRELESS_PLAN_INFO:
      newState.wirelessCartInfo.wirelessPlanInfo = action.payload.wirelessPlanInfo;
      newState.wirelessCartInfo.wirelesServeRef = action.payload.wirelesServeRef;
      newState.wirelessCartInfo.isMailboxRequired = action.payload.isMailboxRequired;
      return newState;

    case types.SET_MOBILE_ONLY_FLOW:
      newState.mobileOnly = action.payload
      return newState;

    case types.SKIP_CREATE_ACCOUNT:
      newState.isSkipCreateAccount = action.payload;
      return newState;
    case types.VAS_SKIP_CREATE_ACCOUNT:
      newState.isVasSkipCreateAccount = action.payload;
      return newState;

    //PENDING API CALL HANDLERS

    case types.UPDATE_ORDER_PENDING:
      newState.hasError = false;
      newState.errorMessage = '';
      newState.loading = true;
      newState.addToCartLoading = true;
      return newState;

    case types.CHECK_SERVICEABILITY_PENDING:
    case types.SERVICE_CHECK_PENDING:
      newState.hasError = false;
      newState.errorMessage = '';
      newState.loading = true;
      return newState;
    case types.SUBMIT_ORDER_PENDING:
      newState.hasError = false;
      newState.errorMessage = '';
      newState.loading = true;
      newState.orderSubLoading = true;
      return newState;
    case types.ACCOUNT_CHECK_PENDING:
      newState.checkingAccount = true;
      return newState;
    case types.VALIDATE_CARD_PENDING:
      newState.loading = true;
      return newState;
    case types.GET_SCHEDULE_APT_PENDING:
      newState.reqInstallDateTime = '';
      newState.scheduleDataLoading = true;
      return newState;
    case types.CHECK_DEVICE_COMPATIBILITY_PENDING:
      newState.deviceCheckLoader = true;
      return newState;

    //REJECTED API CALL HANDLERS

    case types.ACCOUNT_CHECK_REJECTED:
      newState.checkingAccount = false;
      return newState;

    case types.VALIDATE_CARD_REJECTED:
      newState.loading = false;
      return newState;

    case types.SERVICE_CHECK_REJECTED:
    case types.CHECK_SERVICEABILITY_REJECTED:
    case types.INITIAL_ORDER_CREATE_REJECTED:
    case types.UPDATE_ORDER_REJECTED:
    case types.SUBMIT_ORDER_REJECTED:
      newState.orderSubLoading = false;
      newState.loading = false;
      newState.hasError = true;
      return newState;

    case types.SAVE_LANGUAGE_PREF:
      newState.languagePref = action.payload;
      return newState;
    case types.SET_PREFERRED_LANGUAGE:
      newState.isPreferredLangSpanish = action.payload;
      return newState;
    case types.SET_CURRENT_STEP:
      newState.currentStep = action.payload;
      return newState;
    case types.RETAIL_STORE_ADDR:
      newState.retailStoresAddr = action.payload;
      setStoreAddr(action.payload);
      return newState;
    case types.RETAIL_STORES_LOADED:
      newState.storesLoaded = action.payload;
      return newState;

    case types.RETAIL_STORES_FULFILLED:
      newState.storesLoading = false;
      if (action.payload.status === 200) {
        newState.retailStores = action.payload.data.items;
        newState.storesLoaded = true;
      } else {
        newState.hasError = true;
      }
      return newState;
    case types.RETAIL_STORES_PENDING:
      newState.storesLoading = true;
      newState.hasError = false;
      newState.errorMessage = '';
      return newState;
    case types.RETAIL_STORES_REJECTED:
      newState.storesLoading = false;
      newState.hasError = true;
      return newState;
    case types.GET_PROMO_BY_SOURCE_CODE_PENDING:
      newState.promoErr = false;
      newState.promoDetails = {};
      newState.promoDes = '';
      newState.applyPromoErr = '';
      return newState;
    case types.GET_PROMO_BY_SOURCE_CODE_FULFILLED:
      newState.promoDetails = action.payload.data.item;
      newState.promoErr = false;
      newState.applyPromoErr = '';
      newState.promoDes = action.payload?.data?.item?.description;
      return newState;
    case types.GET_PROMO_BY_SOURCE_CODE_REJECTED:
      newState.promoErr = true;
      newState.promoDetails = {}
      newState.promoDes = '';
      newState.applyPromoErr = 'Not found';
      return newState;
    case types.CHECK_PROMO_CODE_TRACKER_PENDING:
      newState.promoTrackerStatus = '';
      newState.promoTrackerErrData = {};
      newState.promoTrackerDetails = {};
      newState.applyPromoErr = '';
      return newState;
    case types.CHECK_PROMO_CODE_TRACKER_FULFILLED:
      newState.promoTrackerStatus = action.payload.status
      newState.promoTrackerErrData = {};
      newState.promoTrackerDetails = action.payload.data;
      newState.applyPromoErr = '';
      return newState;
    case types.CHECK_PROMO_CODE_TRACKER_REJECTED:
      newState.promoTrackerStatus = action.payload.response.status
      newState.promoTrackerErrData = action.payload.response.data;
      newState.applyPromoErr = action.payload.response.data.message;
      newState.promoTrackerDetails = {}
      return newState;
    case types.SET_NEW_PROMO_CODE:
      newState.manualPromoCode = action.payload;
      return newState;
    case types.SET_CART_PROMO_CODE:
      newState.cartPromoCode = action.payload;
      return newState;

    case types.SET_ICCIDS:
      newState.iccids = action.payload;
      return newState;

    case types.SET_SIM_CARD_FLOW:
      newState.isSimCardFlow = action.payload;
      return newState;

    case types.SET_TERM_INFO_CONFIRM:
      newState.isTermInfoConfirm = action.payload;
      return newState;

    case types.SET_MOBILE_PLANS:
      newState.serviceabilityCheck.mobileProducts = action.payload;
      return newState;

    case types.APPLY_PROMO_CODE_PENDING:
      newState.isApplyPromoLoading = true;
      newState.promoDes = '';
      newState.promoDetails = {};
      newState.applyPromoErr = '';
      newState.promoErr = false;
      return newState;
    case types.APPLY_PROMO_CODE_FULFILLED:
      newState.isApplyPromoLoading = false;
      newState.serviceabilityCheck.serviceTerm = action.payload.data.serviceTerm ? action.payload.data.serviceTerm : newState.serviceabilityCheck.serviceTerm;
      newState.promoDes = action.payload.data.description;
      newState.callLogId = action.payload.data.callLogId;
      newState.applyPromoErr = action.payload.data.message;
      return newState;
    case types.APPLY_PROMO_CODE_REJECTED:
      newState.isApplyPromoLoading = false;
      newState.promoDes = '';
      newState.promoDetails = {};
      newState.applyPromoErr = 'Not Found';
      newState.promoErr = true;
      return newState;

    case types.GET_BUNDLES_PENDING:
      newState.bundlesLoading = true;
      return newState;
      case types.GET_BUNDLES_FULFILLED: {
        newState.bundlesLoading = false;
        const mobileBundle = action?.payload?.data?.bundles?.filter((plan) => plan.category?.toLowerCase() === 'mobile')
        const internetBundle = action?.payload?.data?.bundles?.filter((plan) => plan.category?.toLowerCase() === 'ipbb')
        const displayOrder = (mobileBundle?.length === 0 || internetBundle?.length === 0) ? 'singleProdDisplayOrder' : 'multiProdDisplayOrder'
        newState.bundles = action.payload.data.bundles?.map((bundle) => Object.entries(bundle).reduce((obj, [key, value]) => {
          obj[key] = typeof value === 'string' ? value.trim() : value;
          return obj;
        }, {}))
          .filter((bundle) => filterAvailableBundles(bundle, newState.serviceabilityCheck))
          .sort((a, b) => a[displayOrder] - b[displayOrder]) || [];
        return newState;
      }
    case types.GET_BUNDLES_REJECTED:
      newState.bundlesLoading = false;
      return newState;
    case types.SET_BUNDLES_CART:
      newState.bundlesCart = action.payload;
      return newState;
    case types.SET_ORDER_ACCESS_TOKEN:
      newState.orderAccessToken = action.payload;
      return newState;
      case types.SET_SUCCESS_MESSAGE_TERM:
        newState.isSuccessMessageTerm = action.payload;
        return newState;

    default: { return state }
  }
}