import React from 'react';
import * as R from 'ramda';
import { Select, AutoComplete } from 'antd';
import styled from 'styled-components';
import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input/input';
import validator from 'validator';
import { Loader } from '../common/Loader';
import AddressSuggestionSelect from '../common/AddressSuggestionSelect';
import BusinessInfo from './BusinessInfo';
import ApiError from '../common/ApiError';
import { ValidationMessages } from '../../common/validationMessages';
import { alphaWithSpaceRegex } from '../../util/regex';
import {
  getPersonalInfoModel,
  getLanguagePref,
  getAccountCheckModel,
  getCreateAccountInfoModel,
} from '../../helpers/OrderHelperFunctions';
import {
  isUndefinedNullOrEmpty,
  isEmpty,
  getVasFlag,
  checkTollFreeNumber,
  getSegregatedAddress,
  defineParam,
  getAggregatedAddress,
} from '../../common/common';
import { isValidPhoneNumber } from 'react-phone-number-input';
import moment from 'moment-timezone';
import { isMobile } from 'react-device-detect';
import { STATES } from '../../data/states';
import { AccountAlreadyExistModal } from '../common/AccountAlreadyExistModal';
import { SUGGESTED_EMAIL_DOMAINS, config } from '../../util/util';
import {
  undeliverableEmailMessage,
  verificationReasons,
  warningEmailMessage,
} from '../../constants/email';
import { api } from '../../util/axios';
import { Spinner } from 'react-bootstrap';

class EnterInfo extends React.Component {
  constructor(props) {
    super(props);
    const { OrderState } = this.props;
    const { orderInfo, planInfo = {}, normalizedAddress } = OrderState;
    const { vasProducts } = OrderState.serviceabilityCheck;
    const { firstName, lastName, email, phoneNum, shippingAddress } = orderInfo;
    const {
      address1 = '',
      address2 = '',
      city = '',
      state = '',
      zip = '',
    } = !isUndefinedNullOrEmpty(shippingAddress) &&
    getSegregatedAddress(shippingAddress);
    this.state = {
      disclaimerCheck: false,
      email: !isUndefinedNullOrEmpty(email) ? email : '',
      error: {},
      firstName: !isUndefinedNullOrEmpty(firstName) ? firstName : '',
      lastName: !isUndefinedNullOrEmpty(lastName) ? lastName : '',
      phoneNum: !isUndefinedNullOrEmpty(phoneNum) ? `+1${phoneNum}` : '',
      callLogId: OrderState.callLogId,
      languagePref: false,
      isPreferredLangSpanish: false,
      businessState: {
        error: {},
      },
      options:[],
      showCallSupportModal: false,
      emailInvalidMsg: '',
      emailIsAvailable: '',
      showVas:
        vasProducts &&
        vasProducts.length > 0 &&
        getVasFlag().toLowerCase() === 'true',
      isMob: isMobile,
      isReprovisioning: false,
      isHLProductAdded: planInfo && Object.keys(planInfo).length > 1,
      shippingAdd1: defineParam(
        !isUndefinedNullOrEmpty(shippingAddress),
        address1,
        normalizedAddress.address1
      ),
      shippingAdd2: defineParam(
        !isUndefinedNullOrEmpty(shippingAddress),
        address2,
        normalizedAddress.address2
      ),
      shippingCity: defineParam(
        !isUndefinedNullOrEmpty(shippingAddress),
        city,
        normalizedAddress.city
      ),
      shippingState: defineParam(
        !isUndefinedNullOrEmpty(shippingAddress),
        state,
        normalizedAddress.state
      ),
      shippingZip: defineParam(
        !isUndefinedNullOrEmpty(shippingAddress),
        zip,
        normalizedAddress.zipCode
      ),
      shippingAddress: isUndefinedNullOrEmpty(shippingAddress)
        ? getAggregatedAddress(normalizedAddress)
        : shippingAddress,
      isAddressSame: false,
      isManualAddr: false,
      manualAddress: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },
      emailVerificationMessage: '',
      emailVerificationLoading: false,
      label: '',
      type: '',
      installPhone: '',
      isInstallPhoneExpanded: '',
    };
  }

  componentDidMount() {
    if (!this.state.type && this.state.phoneNum) {
      this.handlePhoneChange('phoneNum', this.state.phoneNum);
    }
    sessionStorage.setItem('isPreferredLangSpanish', false);
  }

  handleChange = (event) => {
    const { firstName, lastName } = this.state;
    const { value, name } = event.target;
    if (name === 'firstName' || name === 'lastName') {
      if (!alphaWithSpaceRegex.test(value)) return;
    }
    this.setState({
      [name]: value,
    });
    let { error } = this.state;
    if (error[name]) {
      delete error[name];
    }
    if (name === 'firstName' || name === 'lastName') {
      if (
        firstName?.trim().length >= 2 &&
        lastName?.trim().length >= 2 &&
        firstName.toLowerCase() !== value.toLowerCase() &&
        lastName.toLowerCase() !== value.toLowerCase()
      ) {
        delete error.firstName;
        delete error.lastName;
      }
    }
  };

  handleSelect = (value) => {
    this.setState({
      email: value,
    })
  }

  validateInputFields = (field) => {
    const { firstName, lastName, email, phoneNum, installPhone, isMob } = this.state;
    let error = this.state.error;
    //RESET STATE
    delete error[field];

    //REQUIRED FIELD VALIDATIONS
    if (this.state[field] === '' && field !== 'installPhone') {
      error = {
        ...error,
        [field]: isMob
          ? ValidationMessages.mobRequired
          : ValidationMessages.required,
      };
    }

    //EMAIL VALIDATION
    if (field === 'email' && email !== '' && !validator.isEmail(email)) {
      error = {
        ...error,
        email: ValidationMessages.invalidEmail,
      };
    }

    //VALIDATE FIRST NAME
    if (field === 'firstName' && firstName !== '') {
      if (firstName.length < 2) {
        error = {
          ...error,
          firstName: ValidationMessages.min2Length,
        };
      }
    }
    //VALIDATE LAST NAME
    if (field === 'lastName' && lastName !== '') {
      if (lastName.length < 2) {
        error = {
          ...error,
          lastName: ValidationMessages.min2Length,
        };
      } else if (firstName === lastName) {
        error = {
          ...error,
          lastName: ValidationMessages.shouldNotBeSame,
        };
      }
    }
    if (
      (field === 'firstName' || field === 'lastName') &&
      firstName?.trim().length >= 2 &&
      lastName?.trim().length >= 2 &&
      firstName.toLowerCase() === lastName.toLowerCase()
    ) {
      error = {
        ...error,
        [field]: 'Values must be unique',
      };
    }
    //VALIDATE PHONE NUMBER
    const phoneNumber = field === 'installPhone' ? installPhone : phoneNum;

    if (['installPhone', 'phoneNum'].includes(field) && phoneNumber.length !== 0) {
      if (phoneNumber.length < 10) {
        error = {
          ...error,
          [field]: ValidationMessages.min10Length,
        };
      } else if (!isValidPhoneNumber(phoneNumber)) {
        error = {
          ...error,
          [field]: ValidationMessages.invalidPhoneNumber,
        };
      } else if (checkTollFreeNumber(phoneNumber.toString())) {
        error = {
          ...error,
          [field]: 'Toll-Free numbers are not accepted',
        };
      }
    }
    this.setState({ error });
  };

  checkAcctAvail = () => {
    const { email, firstName, lastName } = this.state;
    this.validateInputFields('email');
    if (email.length > 3) {
      if (
        !isUndefinedNullOrEmpty(email) &&
        !isUndefinedNullOrEmpty(firstName) &&
        !isUndefinedNullOrEmpty(lastName)
      ) {
        const accountCheckModel = getAccountCheckModel(
          firstName,
          lastName,
          email
        );
        Promise.all([
          this.props.OrderActions.checkExistingEmail(email),
          this.props.OrderActions.accountCheck(accountCheckModel),
        ])
          .then(([searchResponse]) => {
            const isExistingAccount = searchResponse?.value?.data?.exist;
            const { accountCheck } = this.props.OrderState;
            const { emailIsAvailable } = accountCheck;
            if (emailIsAvailable && !isExistingAccount) {
              this.setState(
                {
                  emailIsAvailable: true,
                  emailInvalid: false,
                  emailInvalidMsg: '',
                },
                this.handleSubmit,
              );
            } else {
              this.setState({ showCallSupportModal: true });
            }
          })
          .catch(() => {
            this.setState({ showCallSupportModal: true });
          });
      }
    }
  };

  verifyEmail = () => {
    this.setState({ emailVerificationMessage: '' });

    const { email } = this.state;

    if (!email.length) {
      this.setState({ emailVerificationMessage: 'Please input your E-mail' });
    } else if (email.length && !validator.isEmail(email)) {
      this.setState({
        emailVerificationMessage: ValidationMessages.invalidEmail,
      });
    } else {
      this.setState({ emailVerificationLoading: true });

      api.axios
        .get(
          `${config.API_ENDPOINT}/emails/verify?email=${encodeURIComponent(email)}&appClientId=aoe`
        )
        .then((res) => {
          this.setState({ emailVerificationMessage: '' });

          const { reason, result } = res.data;

          if (result === 'undeliverable') {
            this.setState({
              emailVerificationMessage: undeliverableEmailMessage,
            });
          } else if (verificationReasons.includes(reason)) {
            this.setState({ emailVerificationMessage: warningEmailMessage });
          }

          this.setState({ emailVerificationLoading: false });
        })
        .catch(() => this.setState({ emailVerificationLoading: false }));
    }
  };

  changeMobileInfo = (mobileType) => {
    if (mobileType === 'mobile') {
      this.setState({
        type: 'Mobile',
        label: 'Cell',
        labelSelectDisabled: true,
      });
    } else {
      this.setState({
        type: 'Non-Mobile',
        label: 'Home',
        labelSelectDisabled: false,
      });
    }
  };

  handleModalClose = () => {
    this.setState({
      showCallSupportModal: false,
    });
    this.props.OrderActions.resetState();
    this.props.history.push('/');
  };

  handleEditEmail = () => {
    this.setState({
      showCallSupportModal: false,
      email: '',
    });
  };

  handlePhoneChange = (key, value) => {
    this.setState({ [key]: value === undefined ? '' : value });

    if (value?.length === 12 && key === 'phoneNum') {
      const phoneNumber = value.replace('+1', '');

      api.axios
        .get(`${config.API_ENDPOINT}/phone/check/${phoneNumber}`)
        .then((res) => {
          const mobileType = R.pathOr(
            '',
            ['data', 'response', 'carrier', 'type'],
            res
          );

          this.changeMobileInfo(mobileType);
        })
        .catch((err) => {
          const mobileType = R.pathOr('', ['response', 'data', 'message'], err);

          this.changeMobileInfo(mobileType);
        });
    }
  };

  handleSubmit = async () => {
    const { OrderState, isVasOrder, isVasPage, vasSkipCreateAccount } =
      this.props;
    const { address1, address2, city, state, zipCode } =
      OrderState.normalizedAddress;
    const {
      firstName,
      lastName,
      email,
      phoneNum,
      businessState,
      showVas,
      disclaimerCheck,
      languagePref,
      isHLProductAdded,
      shippingAdd1,
      shippingAdd2,
      shippingCity,
      shippingState,
      shippingZip,
      isAddressSame,
      manualAddress,
      isManualAddr,
      isInstallPhoneExpanded,
      installPhone,
    } = this.state;
    const { serviceCheckId } = OrderState.serviceabilityCheck;
    const { businessName, businessPhone, noOfEmployees, noOfLocations } =
      businessState;
    const {
      callLogId,
      isBusinessAddress,
      isReachOrder,
      isVasSkipCreateAccount,
      isElnkMailboxReq,
      orderAccessToken,
    } = OrderState;
    let cellPhoneTermsAgreed;
    if (disclaimerCheck) {
      cellPhoneTermsAgreed = moment().format('lll');
    } else {
      cellPhoneTermsAgreed = 'declined';
    }

    const accountInfoModel = getCreateAccountInfoModel(email, isElnkMailboxReq);
    const personalInfoModel = getPersonalInfoModel(
      firstName,
      lastName,
      callLogId,
      email,
      phoneNum.slice(-10),
      cellPhoneTermsAgreed,
      serviceCheckId,
      isVasOrder
    );
    if (isBusinessAddress) {
      this.props.OrderActions.saveBusinessInfo({
        businessName,
        businessPhone: businessPhone.slice(-10),
        noOfEmployees,
        noOfLocations,
      });
      personalInfoModel.businessName = businessName;
      personalInfoModel.businessPhone = businessPhone.slice(-10);
      personalInfoModel.businessEmployees = noOfEmployees;
      personalInfoModel.businessLocations = noOfLocations;
    }

    if (isInstallPhoneExpanded && installPhone) {
      personalInfoModel.installPhone = installPhone.slice(-10);
    }

    // SHIPPING ADDRESS
    const shippingAddress = getAggregatedAddress({
      address1: shippingAdd1,
      address2: shippingAdd2,
      city: shippingCity,
      state: shippingState,
      zipCode: shippingZip,
    });
    const serviceAddress = getAggregatedAddress({
      address1,
      address2,
      city,
      state,
      zipCode,
    });
    const manualShipAddress = getAggregatedAddress({
      address1: manualAddress.address1,
      address2: manualAddress.address2,
      city: manualAddress.city,
      state: manualAddress.state,
      zipCode: manualAddress.zip,
    });

    let updateOrderBody = {};
    if (isManualAddr && isAddressSame) {
      updateOrderBody = {
        shippingAdd1: manualAddress.address1,
        shippingAdd2: manualAddress.address2,
        shippingAddress: manualShipAddress,
        shippingCity: manualAddress.city,
        shippingState: manualAddress.state,
        shippingZip: manualAddress.zip,
      };
    } else {
      updateOrderBody = {
        shippingAdd1: defineParam(isAddressSame, shippingAdd1, address1),
        shippingAdd2: defineParam(isAddressSame, shippingAdd2, address2),
        shippingAddress: defineParam(
          isAddressSame,
          shippingAddress,
          serviceAddress
        ),
        shippingCity: defineParam(isAddressSame, shippingCity, city),
        shippingState: defineParam(isAddressSame, shippingState, state),
        shippingZip: defineParam(isAddressSame, shippingZip, zipCode),
      };
    }

    //SAVE LANGUAGE PREFERENCE
    this.props.OrderActions.saveLanguagePref(getLanguagePref(languagePref));
    if (typeof this.props.OrderActions.setPreferredLanguage ==='function' ){
      this.props.OrderActions.setPreferredLanguage(getLanguagePref(languagePref));
    }



    //SAVE TO DB CALL
    this.props.OrderActions.updateOrder(
      { ...personalInfoModel, ...accountInfoModel },
      orderAccessToken
    )
      .then(() => {
        if (isVasOrder || (showVas && isHLProductAdded && !isReachOrder)) {
          this.props.onNext();
        } else if (isReachOrder) {
          this.props.OrderActions.updateOrder(updateOrderBody, orderAccessToken).then(() => {
            this.props.skipCreateAccount();
          });
        } else {
          this.props.skipVas();
        }
        if (isVasPage && isVasSkipCreateAccount) {
          vasSkipCreateAccount();
        }
      })
      .catch((error) => {
        this.props.OrderActions.updateErrorMsg(error);
      });
  };

  disclaimerSync = () => {
    this.setState({
      disclaimerCheck: !this.state.disclaimerCheck,
    });
  };

  languageSync = () => {
    this.setState({
      languagePref: !this.state.languagePref,
      isPreferredLangSpanish: !this.state.isPreferredLangSpanish,
    });
    sessionStorage.setItem('isPreferredLangSpanish', !this.state.isPreferredLangSpanish)
  };

  setBusinessState = (businessState) => {
    this.setState({
      businessState,
    });
  };

  handleKeyUp = (key) => {
    const phoneNumber =
      document.getElementById(key) &&
      document.getElementById(key).value;
    if (!isUndefinedNullOrEmpty(phoneNumber)) {
      if (
        phoneNumber.charAt(0) === '+' ||
        (phoneNumber.length === 1 &&
          (phoneNumber === '0' || phoneNumber === '1'))
      ) {
        this.setState({ [key]: null });
      }
      if (
        phoneNumber.charAt(0) === '+' ||
        phoneNumber.charAt(0) === '1' ||
        phoneNumber.charAt(0) === '0'
      ) {
        this.setState({ [key]: null });
      }
    }
    return;
  };

  handleKeyDown = (e) => {
    if (e.key === ' ' && e.currentTarget.value.length === 0) {
      e.preventDefault();
    } // preventing space as first letter
  };

  setBilling = (searchAddress) => {
    this.setState({
      shippingAdd1: searchAddress.address1,
      shippingAdd2: searchAddress.address2,
      shippingCity: searchAddress.city,
      shippingState: searchAddress.state,
      shippingZip: searchAddress.zip,
      shippingAddress: getAggregatedAddress({
        ...searchAddress,
        zipCode: searchAddress.zip,
      }),
    });
  };

  toggleInstallPhone = () => {
    this.setState({
      isInstallPhoneExpanded: !this.state.isInstallPhoneExpanded,
    });
  };

  addressSync = () => {
    this.setState({
      isAddressSame: !this.state.isAddressSame,
    });
  };

  handleManualChange = (e, name) => {
    this.setState({
      manualAddress: {
        ...this.state.manualAddress,
        [name]: name === 'state' ? e : e.target.value,
      },
    });
  };

  handleLabelChange = (label) => {
    this.setState({ label });
  };

  handleCheckboxChange = () => {
    this.setState({
      isManualAddr: !this.state.isManualAddr,
    });
  };

  emailChangeHandler = (emailText) => {
    let res = [];
    const {email} = this.state
    const value = emailText.replace(/\s/g, '').trim();
    if (value?.length && value?.indexOf('@') > 0) {
      res = SUGGESTED_EMAIL_DOMAINS?.map(( domain ) => ({
        label:`${value?.split('@')[0]}@${domain}`,
      }));
    }
    this.setState({ options: res });
    this.setState({
      email: value,
    });
    let { error } = this.state;
    if (error[email]) {
      delete error[email];
    }
  }

  handleNumericKeyPress = (e) => {
    const charCode = e.charCode != null ? e.charCode : e.keyCode;
    const charString = String.fromCharCode(charCode);
    if (!charString.match(/^[\d]+$/)) {
      e.preventDefault();
    }
  };
  render() {
    const { OrderState, isSinglePage } = this.props;
    const {
      loading,
      errorMessage,
      isBusinessAddress,
      isReachOrder,
    } = OrderState;
    const {
      firstName,
      lastName,
      phoneNum,
      email,
      error,
      disclaimerCheck,
      businessState,
      languagePref,
      isPreferredLangSpanish,
      shippingAddress,
      isAddressSame,
      isManualAddr,
      manualAddress,
      showCallSupportModal,
      emailIsAvailable,
      emailInvalidMsg,
      emailVerificationMessage,
      emailVerificationLoading,
      type,
      isHLProductAdded,
      isInstallPhoneExpanded,
      installPhone,
      options,
    } = this.state;
    const { address1, address2, city, state, zip } = this.state.manualAddress;
    //FLAG used to disable submit button
    let isBusinessFieldsValid = false,
      isAllFieldsValid = false;
    isBusinessFieldsValid =
      !isEmpty(businessState.businessName) &&
      !isEmpty(businessState.businessPhone) &&
      !isEmpty(businessState.noOfLocations) &&
      !isEmpty(businessState.noOfEmployees) &&
      Object.keys(businessState.error).length === 0;
    isAllFieldsValid =
      !isEmpty(firstName) &&
      !isEmpty(lastName) &&
      !isEmpty(email) &&
      !isEmpty(phoneNum) &&
      Object.keys(error).length === 0;
    isAllFieldsValid = isBusinessAddress
      ? isAllFieldsValid && isBusinessFieldsValid
      : isAllFieldsValid;
    const isVaildManualAddress =
      manualAddress.address1 !== '' &&
      manualAddress.city !== '' &&
      manualAddress.zip !== '' &&
      manualAddress.zip.length > 4 &&
      manualAddress.state !== '';
    const isShippingAdrrShow = !isAddressSame ? false : !isVaildManualAddress;
    const buttonCheckAvailability = isManualAddr ? isShippingAdrrShow : false;

    return (
      <>
        {!isSinglePage && (
          <div className="card-header">
            <h3 className="serviceTitle mb-0">Enter Your Information</h3>
          </div>
        )}
        <div className="panel panel-primary setup-content step" id="step-2">
          <div>
            {isBusinessAddress ? (
              <BusinessInfo {...this.props} setBusinessState={this.setBusinessState} />
            ) : (
              ''
            )}
            <div className="form-row">
              <div className="form-group  col-md-6 col-sm-6 col-xs-12 padding-0">
                <input
                  maxLength="30"
                  autoComplete="off"
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  onChange={this.handleChange}
                  value={firstName}
                  className="form-control formInputBox"
                  autoFocus={!isBusinessAddress}
                  onBlur={(e) => this.validateInputFields(e.target.name)}
                  onKeyDown={this.handleKeyDown}
                ></input>
                {error.firstName && (
                  <label className="submitErr">{error.firstName}</label>
                )}
              </div>
              <div className="form-group  col-md-6 col-sm-6 col-xs-12">
                <input
                  maxLength="30"
                  autoComplete="off"
                  className="form-control formInputBox"
                  placeholder="Last Name"
                  id="lastName"
                  value={lastName}
                  name="lastName"
                  onChange={this.handleChange}
                  onBlur={(e) => this.validateInputFields(e.target.name)}
                  onKeyDown={this.handleKeyDown}
                ></input>
                {error.lastName && (
                  <label className="submitErr">{error.lastName}</label>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <StyledAutoComplete
                  className="formInputBox"
                  id="inputEmail4"
                  name="email"
                  style={{ borderColor: emailVerificationMessage ? 'red' : '' }}
                  placeholder="Email Address"
                  dataSource={options.map((option) => option.label)}
                  onBlur={this.verifyEmail}
                  onSelect={this.handleSelect}
                  value={email}
                  onChange={this.emailChangeHandler}
                  filterOption={true}
                />
                {emailVerificationMessage && !error.email ? (
                  <span style={{ color: 'red' }}>
                    {emailVerificationMessage}
                  </span>
                ) : (
                  emailVerificationLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      aria-hidden="true"
                    />
                  )
                )}
                {error.email && (
                  <label className="submitErr">{error.email}</label>
                )}
                {!emailIsAvailable && emailInvalidMsg !== '' && (
                  <p className="text-red" style={{ display: 'inline-block' }}>
                    {emailInvalidMsg}
                  </p>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group  col-md-6 col-sm-6 col-xs-12 padding-0">
                <Input
                  autoComplete="off"
                  className="form-control formInputBox"
                  defaultCountry="US"
                  maxLength={14}
                  id="phoneNum"
                  name="phoneNum"
                  onKeyUp={() => this.handleKeyUp('phoneNum')}
                  onBlur={(e) => this.validateInputFields(e.target.name)}
                  onChange={(value) => this.handlePhoneChange('phoneNum', value)}
                  placeholder="Cell Phone Number"
                  value={phoneNum}
                />
                {error.phoneNum && (
                  <label className="submitErr">{error.phoneNum}</label>
                )}
              </div>

              <div className="form-group col-md-6 col-sm-6 col-xs-12 padding-0">
                <div className="d-flex align-items-center" style={{ height: '45px' }}>
                  <HorizontalLabel className="px-2">
                    <b>Type</b>
                  </HorizontalLabel>
                  <TypeLabel color="black" className="text-center px-2">{type}</TypeLabel>
                </div>
              </div>
            </div>

            <div className="text-justify mb-2">
              <b>Primary Number </b>is used to communicate via phone/text
              messages regarding details of your order and other account related
              communications in the future.
            </div>

            {isHLProductAdded && (
              <div className="ccn-wrapper">
                <div className="form-row">
                  <div className="form-group col-md-9 lable_shipping">
                    Activation Phone Number{' '}
                    <span className="text-danger">
                      (if different from the Primary Number)
                    </span>
                  </div>
                  <div className="form-group col-md-3 shipping_icon text-right">
                    <i
                      className={`fas fa-${
                        isInstallPhoneExpanded ? 'minus' : 'plus'
                      }-circle`}
                      onClick={this.toggleInstallPhone}
                    />
                  </div>
                </div>
                {isInstallPhoneExpanded && (
                  <div className="form-row mb-3">
                    <Input
                      autoComplete="off"
                      className="form-control formInputBox"
                      defaultCountry="US"
                      maxLength={14}
                      id="installPhone"
                      name="installPhone"
                      onKeyUp={() => this.handleKeyUp('installPhone')}
                      onBlur={(e) => this.validateInputFields(e.target.name)}
                      onChange={(value) => this.handlePhoneChange('installPhone', value)}
                      placeholder="Phone Number"
                      value={installPhone}
                    />
                    {error.installPhone && (
                      <label className="submitErr">{error.installPhone}</label>
                    )}
                  </div>
                )}
              </div>
            )}

            {isReachOrder && (
              <>
                <div className="ccn-wrapper">
                  <div className="form-row">
                    <div className="form-group col-md-9 lable_shipping">
                      Add Shipping Address{' '}
                      <span className="text-danger">
                        (if different from Service)
                      </span>
                    </div>
                    <div className="form-group col-md-3 shipping_icon text-right">
                      <i
                        className={`fas fa-${
                          isAddressSame ? 'minus' : 'plus'
                        }-circle`}
                        onClick={this.addressSync}
                      ></i>
                    </div>
                  </div>
                  {isAddressSame && (
                    <>
                      <div className="form-row align-items-center">
                        <div className="form-group col-md-9">
                          <AddressSuggestionSelect
                            {...this.props}
                            autoFocus={false}
                            autoComplete="off"
                            parentComp="shipInfo"
                            className="billingInput"
                            setBilling={this.setBilling}
                            val={shippingAddress}
                            name="shippingAddress"
                            disabled={isManualAddr}
                          />
                        </div>
                        <div className="form-group col-md-3 text-right">
                          <input
                            className="form-check-input"
                            name="isManualAddr"
                            id="isManualAddr"
                            onChange={this.handleCheckboxChange}
                            checked={isManualAddr}
                            type="checkbox"
                          />
                          <label htmlFor="isManualAddr" className="mb-0">
                            Enter Manually
                          </label>
                        </div>
                      </div>
                      {isManualAddr && (
                        <>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <input
                                type="text"
                                className="form-control formInputBox"
                                placeholder="Street Address"
                                value={address1}
                                onChange={(e) =>
                                  this.handleManualChange(e, 'address1')
                                }
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Apt, Flr, Unit"
                                value={address2}
                                onChange={(e) =>
                                  this.handleManualChange(e, 'address2')
                                }
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-4">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                value={city}
                                onChange={(e) =>
                                  this.handleManualChange(e, 'city')
                                }
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <Select
                                className="formInputBox"
                                value={state || 'State'}
                                placeholder="State"
                                onChange={(e) =>
                                  this.handleManualChange(e, 'state')
                                }
                                style={{ fontSize: '16px' }}
                              >
                                {Object.keys(STATES).map((abbreviation) => (
                                  <Select.Option
                                    key={abbreviation}
                                    value={abbreviation}
                                  >
                                    {abbreviation}
                                  </Select.Option>
                                ))}
                              </Select>
                            </div>
                            <div className="form-group col-md-4">
                              <input
                                className="form-control"
                                maxLength={5}
                                placeholder="Zip Code"
                                value={zip}
                                onKeyPress={this.handleNumericKeyPress}
                                onChange={(e) =>
                                  this.handleManualChange(e, 'zip')
                                }
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
            <div className="form-row">
              <div className="form-group mb-0">
                <div className="form-check">
                  <input
                    checked={languagePref && isPreferredLangSpanish}
                    className="form-check-input mt-2"
                    name="langCheckbox"
                    id="langCheckbox"
                    type="checkbox"
                    onChange={this.languageSync}
                  />
                  <label className="m-top" htmlFor="langCheckbox">
                    Customer Language Preference is Spanish
                  </label>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <div className="form-check">
                  <input
                    name="disclaimerCheck"
                    className="form-check-input"
                    type="checkbox"
                    id="disclaimerCheck"
                    onClick={this.disclaimerSync}
                    checked={disclaimerCheck}
                    onChange={this.disclaimerSync}
                  />
                  <label htmlFor="disclaimerCheck">
                    Your number will be used to communicate about your account
                    or EarthLink products and services. By proceeding, you agree
                    to receive email, calls, voice or text messages via
                    automated technology at the number and information provided.
                    You may opt-out and change other preferences via My Account,
                    Customer Service or direct response to text messages by
                    texting STOP.
                  </label>
                </div>
              </div>
            </div>

            {!loading ? (
              <button
                className="btn-color-darkblue w-100 mb-2"
                disabled={
                  !isAllFieldsValid ||
                  buttonCheckAvailability ||
                  emailVerificationLoading ||
                  (emailVerificationMessage &&
                    emailVerificationMessage !== warningEmailMessage)
                }
                onClick={this.checkAcctAvail}
              >
                Submit
              </button>
            ) : (
              <div className={'spinDiv text-center w-100'}>
                <Loader />
              </div>
            )}
            {!isUndefinedNullOrEmpty(errorMessage) && <ApiError {...this.props} />}
          </div>
        </div>

        {showCallSupportModal && (
          <AccountAlreadyExistModal
            handleModalClose={this.handleModalClose}
            handleEditEmail={this.handleEditEmail}
          />
        )}
      </>
    );
  }
}

export default EnterInfo;

const HorizontalLabel = styled.span`
  color: ${(props) => props.color || '#3190ff'};
`;

const TypeLabel = styled(HorizontalLabel)`
  text-transform: capitalize;
  width: 100px;
`;

const StyledAutoComplete = styled(AutoComplete)`
  .ant-input {
      border-radius: 1px !important;
      height: 45px !important;
  }
`;