import React from 'react';
import NonServiceable from '../components/order/NonServiceable';
import Order from '../components/order/Order';
import ScheduleInstallation from '../components/order/ScheduleInstallation';
import FooterPage from '../components/footerbar/FooterPage';
import PageNotFound from '../components/common/PageNotFound';
import { HashRouter, Route, Redirect } from 'react-router-dom';
import { LoginContainer } from '../containers/login';
import NavBar from '../components/topbar/NavBar';
import { routesConfig } from '../util/util';
import { isUndefinedNullOrEmpty } from '../common/common';
import { PrivateRoute } from './PrivateRoute';
import {
  OrderThanksContainer,
  ReachContainer,
  VasProductsContainer,
  VasOrderContainer,
} from '../containers/order';
import '../App.css';



const AffiliateLoginContainer = (props) => <LoginContainer {...props} />;

const OrderContainer = (props) => <Order {...props} />;

const VasOrder = (props) => <VasOrderContainer {...props} />;

const VasProducts = (props) => <VasProductsContainer {...props} />;

const ReachOrderPage = (props) => <ReachContainer {...props} />;

const NonServiceableContainer = () => <NonServiceable />;

const OrderThanksContainer1 = (props) => <OrderThanksContainer {...props} />;

const PageNotFoundContainer = (props) => <PageNotFound {...props} />;

const Routes = (props) => {
  const { agentId } = props.LoginState;
  const authenticated = !isUndefinedNullOrEmpty(agentId);

  return (
    <div>
      <NavBar />
      <div
        style={{
          height: 'calc(100vh - 200px)',
          overflowY: 'scroll',
        }}
      >
        <HashRouter>
          <>
            <Route exact path="/">
              {authenticated ? (
                <Redirect to={routesConfig.serviceability} />
              ) : (
                <AffiliateLoginContainer />
              )}
            </Route>
          </>
          <Route exact path={routesConfig.affiliateLogin}>
            {authenticated ? (
              <Redirect to={routesConfig.serviceability} />
            ) : (
              <AffiliateLoginContainer />
            )}
          </Route>
          <PrivateRoute
            authenticated={authenticated}
            exact
            path={routesConfig.nonServiceable}
            component={NonServiceableContainer}
          />
          <PrivateRoute
            authenticated={authenticated}
            exact
            path={routesConfig.order}
            component={OrderContainer}
          />
          <PrivateRoute
            authenticated={authenticated}
            exact
            path={routesConfig.serviceability}
            component={OrderContainer}
          />
          <PrivateRoute
            authenticated={authenticated}
            exact
            path={routesConfig.thanks}
            component={OrderThanksContainer1}
          />
          <PrivateRoute
            authenticated={authenticated}
            exact
            path={routesConfig.vas}
            component={VasProducts}
          />
          <PrivateRoute
            authenticated={authenticated}
            exact
            path={routesConfig.reach}
            component={ReachOrderPage}
          />
          <PrivateRoute
            authenticated={authenticated}
            exact
            path={routesConfig.vasOrder}
            component={VasOrder}
          />
          <PrivateRoute
            authenticated={authenticated}
            exact
            path={'/schedule'}
            component={ScheduleInstallation}
          />
          <Route
            exact
            path={routesConfig.pageNotFound}
            component={PageNotFoundContainer}
          />
        </HashRouter>
      </div>
      <FooterPage />
    </div>
  );
};
export default Routes;
